export default function formatPrice(
  price: number,
  options: { fractionDigits: number } = { fractionDigits: 0 }
): string {
  return Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits
  }).format(price);
}
